body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(255,255,0);
  background: linear-gradient(180deg, rgba(255,255,0,1) 50%,  rgba(0,0,0,1) 100%) no-repeat center center fixed;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.error-page {
  height: 100vh;
}

.sparse-page {
  height: 50vh;
}

.sparse-form {
  max-width: 300px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.ok {
  color: green;
}

.error {
  color: red;
  font-weight: 500;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  border: 1px solid darkgray;
  border-bottom: 3px solid blue;
  color: blue;
  font-weight: bold;
  background-color: lightgray;
}

.nav-tabs .nav-link:hover{
  color: blue;
  font-style: italic;
  background-color: lightgray;
  border: 1px solid darkgray;
  border-bottom: 3px solid lightsteelblue;
}

.nav-tabs .nav-link {
  border: 1px solid darkgray;
  background-color: lightgray;
  color: black;
}

.selected-row {
  background-color:cornflowerblue !important;
}

#identList > tfoot, #statsList > tfoot {
  font-weight: bold;
  background-color: lightgray;
}

#userWelcome {
  margin-right: 0.5em !important;
}

.underConstruction {
  font-size: 5.9vw;
  color: white;
  font-weight: 500;
}

.homeList {
  list-style-type: none;
  margin-bottom: 3em;
}

.homeList li {
  margin-top: 1em;
  font-style: italic;
  font-weight: bold;
}


.loginLink {
  font-weight: 500;
  color: lightgrey;
}

.loginLink a:visited, a:active, a:hover {
  color: white;
}

.homeBottom {
  color: black;
}